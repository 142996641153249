import { React, useEffect, useState } from "react";
import { BackHeader } from "../../components/BackHeader/BackHeader";

import { ReactComponent as DividerHorSVG } from "../../assets/divider_hor.svg";
import { ReactComponent as IconCheckSVG } from "../../assets/icon_check.svg";
import { ReactComponent as IconEditSVG } from "../../assets/icon_edit.svg";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useNavigate, useParams } from "react-router-dom";

export const PitchPage = () => {
  const { eventID, title, section, idPlayer } = useParams();
  const [playerData, setPlayer] = useState();
  const [attempts, setAttempts] = useState([]);
  const [activeSectionName, setActiveSectionName] = useState("");
  const [index, setIndex] = useState(-1);
  const [showSummary, setShowSummary] = useState(false);
  const MAX_ATTEMPTS = 5;
  const navigate = useNavigate();

  const options = [
    { pts: 100, text: "Bullseye" },
    { pts: 50, text: "Out bullseye" },
    { pts: 0, text: "Target miss" },
  ];

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const onloadHandleAttempts = (scores) => {
    setAttempts(scores);
    setShowSummary(scores.length >= MAX_ATTEMPTS ? true : false);
  };

  const saveResult = () => {
    if (activeSectionName) {
      if (index >= 0) {
        // Update specific score
        const updatedAtt = [...attempts];
        updatedAtt[index] = options.filter(
          (item) => item.text === activeSectionName
        )[0].pts;
        setAttempts(updatedAtt);
      } else {
        // Add new score
        setAttempts((existingItems) => {
          return [
            ...existingItems,
            options.filter((item) => item.text === activeSectionName)[0].pts,
          ];
        });
      }
      // Save to DB
      saveToDB(
        options.filter((item) => item.text === activeSectionName)[0].pts,
        index
      ).catch(console.error);
      //console.log(saving);
      setShowSummary(true);
      setActiveSectionName("");
      setIndex(-1);
    }
  };

  const confirm = () => {
    navigate(`/app/finalScore/${eventID}/${title}/${section}/${idPlayer}`);
  };

  const showEvent = (key) => {
    setShowSummary(false);
    setIndex(key);
  };

  const score_next = () => {
    setShowSummary(false);
    setIndex(-1);
  };

  // Select box
  const selectOption = (text, key) => {
    setActiveSectionName(text);
  };

  const getTotalPtsSection = (attempts) => {
    return attempts.reduce((a, b) => a + b, 0);
  }

  const saveToDB = async (pts, index) => {
    fetch(SERVER_URL + "/saveResultP", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
      body: JSON.stringify({
        player_id: idPlayer,
        station: "pitching",
        score: pts,
        no_attempt: index,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          
        }
      });
  };

  const getEventData = async (eventId) => {
    fetch(SERVER_URL + "/getPlayer", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
      body: JSON.stringify({ player_id: idPlayer }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setPlayer(data.data);
          onloadHandleAttempts(data.data.pitching);
        }
      });
  };

  useEffect(() => {
    // call the function
    getEventData(eventID)
      // make sure to catch any error
      .catch(console.error);
    //Check if we have already # attempts
  }, [eventID]);

  const page_title =
    (section === "pitching" ? "Pitch" : section === "hitting" ? "Hit" : "Run") +
    " station";

  return (
    <div className="w-full desktop:w-[700px] h-screen m-auto" style={{paddingLeft: "16px", paddingRight: "16px"}}>
      <BackHeader
        title={page_title}
        back_path={`/app/searchPlayer/${eventID}/${title}/${section}`}
        extra_style={{ paddingTop: "35px" }}
      />
      {playerData && (
        <div className="mainPage">
          <div className="infoPlayer">
            <span className="infoPlayer_name">{`${playerData.player_information["First Name"]} ${playerData.player_information["Last Name"]} #${playerData.player_information["no_id"]}`}</span>
            <span className="infoPlayer_score">{`Score: ${getTotalPtsSection(attempts)} PTS`}</span>
            <span className="infoPlayer_score">{`Attempts: ${attempts.length}/${MAX_ATTEMPTS}`}</span>
          </div>
          <div className="w-full mt-[35px]">
            <DividerHorSVG />
          </div>
          {!showSummary && (
            <div className="scoring">
              <div className="scoring_info">
                <div className="scoring_text">
                  <span className="attempt_main">{`Attempt ${
                    index >= 0 ? index + 1 : attempts.length + 1
                  }`}</span>
                  <span className="attempt_second">
                    Please choose an option
                  </span>
                </div>
              </div>
              <div className="scoring_option">
                <div className="stations">
                  {options.map((item, key) => (
                    <div
                      key={key}
                      className={
                        activeSectionName === item.text
                          ? "active_station"
                          : "station"
                      }
                      style={{ alignItems: "center" }}
                      onClick={() => {
                        selectOption(item.text);
                      }}
                    >
                      <span
                        className="opt_points"
                        style={{
                          color:
                            activeSectionName === item.text
                              ? "#FFFFFF"
                              : "#0041EB",
                        }}
                      >
                        {`${item.pts} PTS`}
                      </span>
                      <span
                        className="opt_label"
                        style={{
                          color:
                            activeSectionName === item.text
                              ? "#FFFFFF"
                              : "#0A141E",
                        }}
                      >
                        {" "}
                        {item.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  className="sub_button"
                  style={{
                    background: activeSectionName ? "#0041EB" : "#B1B7BD",
                  }}
                  onClick={() => saveResult()}
                >
                  <span>Submit</span>
                </div>
              </div>
            </div>
          )}
          {showSummary && (
            <div className="summarySection">
              <div className="text-left">
                <span className="summary_title">Summary</span>
                <span className="summary_desc">
                  If you need to add another attempt, just edit the one of your
                  choise
                </span>
              </div>
              {attempts.map((attempt, key) => (
                <div className="card_attempt" key={key}>
                  <div className="card_left">
                    <IconCheckSVG />
                    <div className="card_attempt_no">
                      <span>{key + 1}</span>
                    </div>
                    <div className="card_desc">
                      <span className="card_desc_title">{`Score: ${attempt} pts`}</span>
                    </div>
                  </div>
                  <div
                    className="card_right"
                    onClick={() => showEvent(key)}
                  >
                    <IconEditSVG />
                  </div>
                </div>
              ))}
              {attempts.length >= MAX_ATTEMPTS ? (
                <>
                <h2 className="totalAttempt">{`Total: ${getTotalPtsSection(attempts)} PTS`}</h2>
                  <div
                    className="score_next"
                    style={{ marginBottom: "25px" }}
                    onClick={() => confirm()}
                  >
                    <span>Confirm</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="score_next" onClick={() => score_next()}>
                    <span>Score next attempt</span>
                  </div>
                  <div className="score_other_player" onClick={() => navigate(`/app/searchPlayer/${eventID}/${title}/${section}`)}>
                    <span>Score another player</span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
