import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RightArrowSVG } from "../../assets/vector_right_arrow.svg";

import { teamsMap } from "../../utils/teamsMap";

import "./style.css";

export const EventList = ({ title, items, extra_style }) => {
  const options_date = { year: 'numeric', month: 'long', day: 'numeric' };
  const navigate = useNavigate();

  return (
    <div className="event_list" style={extra_style}>
      <div className="list_header">
        <span className="list_text">{title}</span>
        <div className="list_number">{items.length}</div>
      </div>
      <div className="card_list">
        {items?.map( (item, key) => {
          // Check if we have the team in app
          const teamInMap = teamsMap.filter((team) => {
            // We use special_key when we have teams such as NY Meets, A's
            return item.name.includes(
              "special_key" in team ? team.special_key : team.shortname
            );
          });
          // If not, assign EventLogo as default
          const team = teamInMap.length !== 0 ? teamInMap[0] : teamsMap[0];

          return (
            <div key={key} className="card_list_item" onClick={ () => navigate(`/app/eventDetail/${item._id}/${item.name}`)}>
              <div className="section1">
                <div
                  className="teamIconList"
                  style={{ backgroundImage: `url(${team.image})` }}
                ></div>
                <div className="caption">
                  <span className="date">{new Date(item.date).toLocaleDateString("en-US", options_date)}</span>
                  <span className="info">{item.name}</span>
                </div>
              </div>
              <RightArrowSVG className="right_arrow"/>
            </div>
          )})}
      </div>
    </div>
  );
};
