import { React, useState } from "react";
import { ReactComponent as SearchSVG } from "../../assets/icon_search.svg";
import { ReactComponent as CloseSVG } from "../../assets/icon_close.svg";

import "./style.css";

export const SearchBarActive = ({ changeText, extra_style}) => {
  const [inputText, setInputText] = useState("");
  const inputHandler = (e) => {
    //convert input text to lower case
    const lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    changeText(lowerCase);
  };

  const clearInput = () => {
      setInputText("");
      changeText("");
  }

  return (
    <div
      className="search_item"
      style={extra_style}
    >
      <div className="search_box_active w-full flex flex-row items-center">
        <SearchSVG />
        <input
          type="text"
          onChange={(e) => {
            inputHandler(e);
          }}
          placeholder={""}
          className="search_text_active"
          value={inputText}
        />
        <div className="mr-[6px]" onClick={()=>clearInput()}>
        <CloseSVG />
        </div>
      </div>
    </div>
  );
};
