import { useEffect } from "react";
import { Header } from "../../components/Header";
import { SearchBar } from "../../components/SearchBar";
import { SeasonComponent } from "../../components/SeasonComponent";
import { CarouselSection } from "../../components/CarouselSection/CarouselSection";
import { Footer } from "../../components/Footer/Footer";
import { LargeCard } from "../../components/LargeCard/LargeCard";

import { ReactComponent as CalendarSVG } from "../../assets/icon_calendar.svg";
import { ReactComponent as DoneCalendarSVG } from "../../assets/icon_done_calendar.svg";
import { ReactComponent as CTASingleSVG } from "../../assets/cta_single_link.svg";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const AdminHomePage = () => {
  const [items_upcoming, setItemsUpcoming] = useState([]);
  const [items_done, setItemsDone] = useState([]);
  const [items_today, setItemsToday] = useState([]);

  const navigate = useNavigate();

  const generateTodayCards = (items) => {
    if (items.length > 0) {
      const initial_item = [
        <div
          className="today_card"
          onClick={() => navigate(`/app/events/Today`)}
        ></div>,
      ];
      const new_cards = items.map((item, key) => (
        <div key={key}>
          <LargeCard
            time_remaining={1.5}
            title={item.name}
            no_players={148}
            navigation_option={() =>
              navigate(`/app/eventDetail/${item._id}/${item.name}`, {
                state: {
                  eventId: item._id,
                  name: item.name,
                },
              })
            }
          />
        </div>
      ));
      const final_items = initial_item.concat(new_cards);
      console.log(final_items);
      return final_items;
    } else {
      return [];
    }
  };

  const generateUpcomingCards = (items) => {
    if (items.length > 0) {
      const initial_item = [
        <div
          className="upcoming_card"
          onClick={() => navigate(`/app/events/Upcoming`)}
        ></div>,
      ];
      const new_cards = items.map((item, key) => {
        const item_date = new Date(item.date);
        return (
          <div
            className="upcoming_card_event"
            onClick={() =>
              navigate(`/app/eventDetail/${item._id}/${item.name}`)
            }
          >
            <div>
              <div className="upcoming_card_date">
                <div className="upcoming_card_date_no">
                  <span>{item_date.getDate()}</span>
                </div>
                <div className="upcoming_card_date_calendar">
                  <span>
                    {item_date.toLocaleString("default", { month: "short" })}
                  </span>
                  <CalendarSVG />
                </div>
              </div>
              <div className="upcoming_card_name">
                <span>{item.name}</span>
              </div>
              <div className="upcoming_card_link">
                <CTASingleSVG />
              </div>
            </div>
          </div>
        );
      });
      const final_items = initial_item.concat(new_cards);
      console.log(final_items);
      return final_items;
    } else {
      return [];
    }
  };

  const generateCompleteCards = (items) => {
    if (items.length > 0) {
      const initial_item = [
        <div
          className="done_card"
          onClick={() => navigate(`/app/events/Completed`)}
        ></div>,
      ];
      const new_cards = items.map((item, key) => {
        const item_date = new Date(item.date);
        return (
          <div
            className="done_card_event"
            onClick={() =>
              navigate(`/app/eventDetail/${item._id}/${item.name}`)
            }
          >
            <div>
              <div className="upcoming_card_date">
                <div className="upcoming_card_date_no">
                  <span>{item_date.getDate()}</span>
                </div>
                <div className="upcoming_card_date_calendar">
                  <span>
                    {item_date.toLocaleString("default", { month: "short" })}
                  </span>
                  <DoneCalendarSVG />
                </div>
              </div>
              <div className="upcoming_card_name">
                <span>{item.name}</span>
              </div>
              <div className="done_card_link">
                <span className="complete_text">Complete</span>
                <CTASingleSVG />
              </div>
            </div>
          </div>
        );
      });
      const final_items = initial_item.concat(new_cards);
      console.log(final_items);
      return final_items;
    } else {
      return [];
    }
  };

  const SERVER_URL = useBaseURL();
  const { user } = useAuth();
  const todayDate = new Date();

  const filterEvents = (items) => {
    const newToday = [];
    const newPast = [];
    const newFuture = [];
    items?.map((item) =>
      new Date(item.date).getFullYear() === todayDate.getFullYear() &&
      new Date(item.date).getMonth() === todayDate.getMonth() &&
      new Date(item.date).getDate() === todayDate.getDate()
        ? newToday.push(item)
        : new Date(item.date).getTime() > todayDate.getTime()
        ? newFuture.push(item)
        : newPast.push(item)
    );
    return { newToday, newPast, newFuture };
  };

  const getEvents = async () => {
    fetch(SERVER_URL + "/getEvents", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          const { newToday, newPast, newFuture } = filterEvents(data.data);
          setItemsToday(generateTodayCards(newToday));
          setItemsUpcoming(generateUpcomingCards(newFuture));
          setItemsDone(generateCompleteCards(newPast));
        }
      });
  };

  useEffect(() => {
    // call the function
    getEvents()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <div className="h-full">
      <Header
        extra_style={{
          paddingTop: "24px",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      />
      <SearchBar
        caption={"Search players, IDs or events"}
        extra_style={{
          marginTop: "16px",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      />
      <SeasonComponent
        extra_style={{
          marginTop: "16px",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      />
      {items_today.length > 0 && (
        <CarouselSection
          main_tile="Today"
          showSeeAll={true}
          seeAllPath={'/app/events/Today'}
          items={items_today}
          extra_style={{ marginTop: "25px", paddingLeft: "16px" }}
          naturalSlideHeight={240}
          naturalSlideWidth={320}
          deleteOne={true}
        />
      )}
      {items_upcoming.length > 0 && (
        <CarouselSection
          main_tile="Upcoming"
          showSeeAll={true}
          seeAllPath={'/app/events/Upcoming'}
          items={items_upcoming}
          extra_style={{ marginTop: "25px", paddingLeft: "16px" }}
          naturalSlideHeight={140}
          naturalSlideWidth={190}
          deleteOne={true}
        />
      )}
      {items_done.length > 0 && (
        <CarouselSection
          main_tile="Recently"
          showSeeAll={true}
          seeAllPath={'/app/events/Completed'}
          items={items_done}
          extra_style={{
            marginTop: "25px",
            paddingLeft: "16px",
            marginBottom: "80px"
          }}
          naturalSlideHeight={140}
          naturalSlideWidth={190}
          deleteOne={true}
        />
      )}
      <Footer extra_style={{ paddingRight: "16px", paddingLeft: "16px" }} />
    </div>
  );
};
