import { React, useEffect, useState } from "react";
import { BackHeader } from "../../components/BackHeader/BackHeader";
import { SearchBar } from "../../components/SearchBar";
import { QuickFilters } from "../../components/QuickFilters/QuickFilters";
import { EventList } from "../../components/EventList/EventList";
import { Footer } from "../../components/Footer/Footer";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useParams } from "react-router-dom";

export const EventHomePage = () => {
  const { section } = useParams();
  const [todayEvents, setToday] = useState();
  const [upcomingEvents, setUpcoming] = useState();
  const [completedEvents, setCompleted] = useState();

  const [selectedPill, setPill] = useState(section);
  const [inputText, setInputText] = useState("");

  const todayDate = new Date();

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const filterEvents = (items) => {
    const newToday = [];
    const newPast = [];
    const newFuture = [];
    items?.map((item) =>
      new Date(item.date).getFullYear() === todayDate.getFullYear() &&
      new Date(item.date).getMonth() === todayDate.getMonth() &&
      new Date(item.date).getDate() === todayDate.getDate()
        ? newToday.push(item)
        : new Date(item.date).getTime() > todayDate.getTime()
        ? newFuture.push(item)
        : newPast.push(item)
    );
    return { newToday, newPast, newFuture };
  };

  const getEvents = async () => {
    fetch(SERVER_URL + "/getEvents", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          const { newToday, newPast, newFuture } = filterEvents(data.data);
          setToday(newToday);
          setCompleted(newPast);
          setUpcoming(newFuture);
        }
      });
  };

  useEffect(() => {
    // call the function
    getEvents()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return todayEvents && upcomingEvents && completedEvents &&(
    <div className="h-full m-auto">
      <BackHeader
        title={"Events"}
        back_path="/app/home"
        extra_style={{
          paddingTop: "24px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      />
      <SearchBar
        caption={"Search an event"}
        extra_style={{
          marginTop: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
        inputText={inputText}
        setInputText={setInputText}
      />
      <QuickFilters
        items={[
          `All (${
            todayEvents.length + upcomingEvents.length + completedEvents.length
          })`,
          `Today (${todayEvents.length})`,
          `Upcoming (${upcomingEvents.length})`,
          `Completed (${completedEvents.length})`,
        ]}
        extra_style={{ marginTop: "24px", paddingLeft: "16px" }}
        initial_value={section}
        changePill={setPill}
      />
      {selectedPill?.includes("All") && (
        <>
          {todayEvents.length > 0 && (
            <EventList
            title="Today"
            items={todayEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
            extra_style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          />
          )}
          {upcomingEvents.length > 0 && (
            <EventList
              title="Upcoming"
              items={upcomingEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
              extra_style={{
                marginTop: "24px",
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            />
          )}
          {completedEvents.length > 0 && (
            <EventList
              title="Completed"
              items={completedEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
              extra_style={{
                marginTop: "24px",
                paddingLeft: "16px",
                paddingRight: "16px",
                marginBottom: "90px",
              }}
            />
          )}
        </>
      )}
      {selectedPill?.includes("Today") &&
        (todayEvents.length > 0 ? (
          <EventList
            title="Today"
            items={todayEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
            extra_style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          />
        ) : (
          <div
            className="car_section"
            style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <div className="header_div">
              <div className="left_section">
                <h1 className="main_title">Today</h1>
                <div className="numberResult">
                  <span className="textResult">0</span>
                </div>
              </div>
            </div>
            <h1 className="main_title" style={{ marginTop: "20px" }}>
              No events today
            </h1>
          </div>
        ))}
      {selectedPill?.includes("Upcoming") &&
        (upcomingEvents.length > 0 ? (
          <EventList
            title="Upcoming"
            items={upcomingEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
            extra_style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "90px",
            }}
          />
        ) : (
          <div
            className="car_section"
            style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <div className="header_div">
              <div className="left_section">
                <h1 className="main_title">Upcoming</h1>
                <div className="numberResult">
                  <span className="textResult">0</span>
                </div>
              </div>
            </div>
            <h1 className="main_title" style={{ marginTop: "20px" }}>
              No upcoming events
            </h1>
          </div>
        ))}
      {selectedPill?.includes("Completed") &&
        (completedEvents.length > 0 ? (
          <EventList
            title="Completed"
            items={completedEvents.filter(event => event.name.toLowerCase().includes(inputText.toLowerCase()))}
            extra_style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginBottom: "90px",
            }}
          />
        ) : (
          <div
            className="car_section"
            style={{
              marginTop: "24px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <div className="header_div">
              <div className="left_section">
                <h1 className="main_title">Completed</h1>
                <div className="numberResult">
                  <span className="textResult">0</span>
                </div>
              </div>
            </div>
            <h1 className="main_title" style={{ marginTop: "20px" }}>
              No completed events
            </h1>
          </div>
        ))}
      <Footer extra_style={{ paddingRight: "16px", paddingLeft: "16px" }} />
    </div>
  );
};
