import * as React from "react";

import { ReactComponent as LogoSVG } from "../assets/logo_header.svg";
import { ReactComponent as SettingsSVG } from "../assets/icon_settings.svg";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/authenticate";

export const Header = ({ pages, extra_style }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path) => {
    setAnchorElNav(null);
    if (path) {
      navigate(path);
    }
  };

  return (
    <div className="header_item w-full flex flex-row items-center" style={extra_style}>
      <LogoSVG />
      <SettingsSVG className="ml-auto"/>
    </div>
  );
};
