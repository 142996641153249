import React from "react";

import "./style.css";

export const ProgressBarLine = ({ percentage, extra_style }) => {
  return (
    <div className="progress_bar_div" style={extra_style}>
      <div className="progress_line">
        <div className="progress_bar" style={{width: `${percentage}%`}}></div>
      </div>
      <div className="progress_text"><span>{percentage}%</span></div>
    </div>
  );
};
