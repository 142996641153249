import { React, useEffect, useState } from "react";
import { LargeDetailCard } from "../../components/LargeDetailCard/LargeDetailCard";
import { QuickFilters } from "../../components/QuickFilters/QuickFilters";
import { SearchBar } from "../../components/SearchBar";
import { EventListPlayer } from "../../components/EventListPlayer/EventListPlayer";

import { ReactComponent as PitchSVG } from "../../assets/icon_pitch.svg";
import { ReactComponent as HitSVG } from "../../assets/icon_hit.svg";
import { ReactComponent as RunSVG } from "../../assets/icon_run.svg";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useParams } from "react-router-dom";
import { useCallback } from "react";

export const EventDetailPage = () => {
  const { eventId, title } = useParams();
  const [rosterData, setRoster] = useState();
  const [selectedPillSection, setPillSection] = useState("Sections");
  const [selectedPillDivisionP, setPillDivisionP] = useState("All");
  const [selectedPillDivisionL, setPillDivisionL] = useState("All");
  const [activeSectionName, setAactiveSectionName] = useState("");
  const [searchInputP, setSearchInputP] = useState(""); // For player section
  const [searchInputL, setSearchInputL] = useState(""); // for leaderboard section

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const getEventData = useCallback(async (eventId) => {
    fetch(SERVER_URL + "/getRoster", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setRoster(data.data);
        }
      });
  }, [SERVER_URL, user]);

  const filterByNameOrIDP = (item) => {
    return (
      item.player_information["First Name"]
        .toLowerCase()
        .startsWith(searchInputP.toLowerCase()) ||
      item.player_information["Last Name"]
        .toLowerCase()
        .startsWith(searchInputP.toLowerCase()) ||
      item.player_information["no_id"].toString().startsWith(searchInputP)
    );
  };

  const filterByNameOrIDL = (item) => {
    return (
      item.player_information["First Name"]
        .toLowerCase()
        .startsWith(searchInputL.toLowerCase()) ||
      item.player_information["Last Name"]
        .toLowerCase()
        .startsWith(searchInputL.toLowerCase()) ||
      item.player_information["no_id"].toString().startsWith(searchInputL)
    );
  };

  const filterByCategoryP = (item) => {
    const divs = selectedPillDivisionP.split(" "); // Age, Sport
    const ages = divs[0].split("-");
    return selectedPillDivisionP === "All"
      ? true
      : item.player_information.Sport.startsWith(divs[1]) &&
          (item.player_information.age === parseInt(ages[0]) ||
            item.player_information.age === parseInt(ages[1]));
  };

  const filterByCategoryL = (item) => {
    const divs = selectedPillDivisionL.split(" "); // Age, Sport
    const ages = divs[0].split("-");
    return selectedPillDivisionL === "All"
      ? true
      : item.player_information.Sport.startsWith(divs[1]) &&
          (item.player_information.age === parseInt(ages[0]) ||
            item.player_information.age === parseInt(ages[1]));
  };

  const start_process = (section_name) => {
    setAactiveSectionName(section_name);
  };

  const getScored = () => {
    return rosterData.filter(
      (item) =>
        item.pitching.length > 0 ||
        item.hitting.length > 0 ||
        item.running.length > 0
    );
  };

  const getDone = () => {
    return rosterData.filter(
      (item) =>
        item.pitching.length === 5 &&
        item.hitting.length === 5 &&
        item.running.length === 2
    );
  };

  useEffect(() => {
    // call the function
    getEventData(eventId)
      // make sure to catch any error
      .catch(console.error);
  }, [getEventData, eventId]);

  return (
    rosterData && (
      <div className="w-full desktop:w-[700px] h-screen m-auto">
        <LargeDetailCard
          title={title}
          back_path="/app/events/All"
          extra_style={{
            paddingTop: "24px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          no_players={rosterData.length}
          no_scored={getScored().length}
          no_done={getDone().length}
          button_text={"Add new player"}
          eventID={eventId}
          main_color={"#FFFFFF"}
        />
        <QuickFilters
          items={["Sections", "Players", "LeaderBoard"]}
          extra_style={{ marginTop: "24px", paddingLeft: "16px" }}
          initial_value={"Sections"}
          changePill={setPillSection}
          extra_funcs={true}
          resetPlayer={setPillDivisionP}
          resetLeader={setPillDivisionL}
        />
        <div className="subcontent">
          {selectedPillSection === "Sections" && (
            <>
              <h1 className="subcontent_title">Please choose a section</h1>
              <div className="stations">
                <div
                  className={
                    activeSectionName === "pitching"
                      ? "active_station"
                      : "station"
                  }
                  onClick={() => {
                    start_process("pitching");
                  }}
                >
                  <PitchSVG
                    className=""
                    stroke={activeSectionName === "pitching" ? "#FFFFFF" : ""}
                  />
                  <div className="station_text">
                    <span
                      className="station_text_h1"
                      style={
                        activeSectionName === "pitching"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      Pitch
                    </span>
                    <span
                      className="station_text_h2"
                      style={
                        activeSectionName === "pitching"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      5 attempts
                    </span>
                  </div>
                </div>
                <div
                  className={
                    activeSectionName === "hitting"
                      ? "active_station"
                      : "station"
                  }
                  onClick={() => {
                    start_process("hitting");
                  }}
                >
                  <HitSVG
                    className=""
                    stroke={activeSectionName === "hitting" ? "#FFFFFF" : ""}
                  />
                  <div className="station_text">
                    <span
                      className="station_text_h1"
                      style={
                        activeSectionName === "hitting"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      Hit
                    </span>
                    <span
                      className="station_text_h2"
                      style={
                        activeSectionName === "hitting"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      5 attempts
                    </span>
                  </div>
                </div>
                <div
                  className={
                    activeSectionName === "running"
                      ? "active_station"
                      : "station"
                  }
                  onClick={() => {
                    start_process("running");
                  }}
                >
                  <RunSVG
                    className=""
                    stroke={activeSectionName === "running" ? "#FFFFFF" : ""}
                  />
                  <div className="station_text">
                    <span
                      className="station_text_h1"
                      style={
                        activeSectionName === "running"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      Run
                    </span>
                    <span
                      className="station_text_h2"
                      style={
                        activeSectionName === "running"
                          ? { color: "#FFFFFF" }
                          : {}
                      }
                    >
                      2 attempts
                    </span>
                  </div>
                </div>
              </div>
              {activeSectionName && (
                <>
                  <h1 className="subcontent_title">Choose a player</h1>
                  <SearchBar
                    caption={"Search players, IDs or events"}
                    extra_style={{
                      marginTop: "16px",
                    }}
                    go_to={`/app/searchPlayer/${eventId}/${title}/${activeSectionName}`}
                  />
                </>
              )}
            </>
          )}
          {selectedPillSection === "Players" && rosterData && (
            <>
              <h1 className="subcontent_title">Select a profile</h1>
              <SearchBar
                caption={"Search player by name or ID"}
                extra_style={{
                  marginTop: "16px",
                }}
                inputText={searchInputP}
                setInputText={setSearchInputP}
              />
              <QuickFilters
                items={[
                  "All",
                  "7-8 B",
                  "9-10 B",
                  "11-12 B",
                  "13-14 B",
                  "7-8 S",
                  "9-10 S",
                  "11-12 S",
                  "13-14 S",
                ]}
                extra_style={{ marginTop: "24px", paddingLeft: "16px" }}
                initial_value={"All"}
                changePill={setPillDivisionP}
              />
              {rosterData && (
                <EventListPlayer
                  items={rosterData
                    .filter(
                      (item) =>
                        filterByNameOrIDP(item) && filterByCategoryP(item)
                    )
                    .sort((a, b) =>
                      a.player_information["Last Name"].localeCompare(
                        b.player_information["Last Name"]
                      )
                    )}
                  extra_style={{ marginTop: "24px", marginBottom: "24px" }}
                  with_leaderboard={false}
                  with_scoring={false}
                />
              )}
            </>
          )}
          {selectedPillSection === "LeaderBoard" && rosterData && (
            <>
              <h1 className="subcontent_title">Select a profile</h1>
              <SearchBar
                caption={"Search player by name or ID"}
                extra_style={{
                  marginTop: "16px",
                }}
                inputText={searchInputL}
                setInputText={setSearchInputL}
              />
              <QuickFilters
                items={[
                  "All",
                  "7-8 B",
                  "9-10 B",
                  "11-12 B",
                  "13-14 B",
                  "7-8 S",
                  "9-10 S",
                  "11-12 S",
                  "13-14 S",
                ]}
                extra_style={{ marginTop: "24px", paddingLeft: "16px" }}
                initial_value={"All"}
                changePill={setPillDivisionL}
              />
              {rosterData && (
                <EventListPlayer
                  items={rosterData
                    .filter(
                      (item) =>
                        filterByNameOrIDL(item) && filterByCategoryL(item)
                    )
                    .sort((a, b) => b.total_score - a.total_score)}
                  extra_style={{ marginTop: "24px", marginBottom: "24px" }}
                  with_leaderboard={true}
                  with_scoring={false}
                />
              )}
            </>
          )}
          {selectedPillSection === "Details" && <span>Details</span>}
        </div>
      </div>
    )
  );
};
