import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Podium1SVG } from "../../assets/icon_podium1.svg";
import { ReactComponent as Podium2SVG } from "../../assets/icon_podium2.svg";
import { ReactComponent as Podium3SVG } from "../../assets/icon_podium3.svg";
import { ReactComponent as RightArrowSVG } from "../../assets/vector_right_arrow.svg";
import { ProgressBarLine } from "../ProgressBarLine/ProgressBarLine";

import { getPtsRunning } from "../../utils/scoring";

import "./style.css";

export const EventListPlayer = ({ items, station, extra_style, with_leaderboard, with_scoring, extra_data }) => {

  const navigate = useNavigate();
  const base_url = `/app/${extra_data?.station}`
  const set_score = (item) => {return station ? scoreByStation(station, item) : item.total_score}

  const scoreByStation = (station, item) => {
    return station === "running" ? getPtsRunningFromArray(item[station]) : item[station].reduce((a, b) => a + b, 0);
  }

  const getPtsRunningFromArray = (running_array) => {
    const time = running_array.length === 0 ? 0 : Math.min(...running_array);
    return getPtsRunning(time);
  }

  const setProgress = (items, station) => {
    const totalProgress = station === "running" ? "2" : "5";
    return `${items.length}/${totalProgress}`
  }

  const setNumProgress = (items, station) => {
    const totalProgress = station === "running" ? 2 : 5;
    return Math.trunc(items.length/totalProgress * 100);
  }

  return (
    <div className="event_list" style={extra_style}>
      <div className="card_list">
        {items?.map( (item, key) =>
        <div key={key} className="card_list_item" onClick={() => with_scoring && navigate(base_url+`/${item.event_id}/${extra_data.title}/${extra_data.station}/${item._id}`) }>
          <div className="section1">
            {with_leaderboard ? key===0 ? <Podium1SVG/> : key===1 ? <Podium2SVG /> : key===2 ? <Podium3SVG /> : <span className="podium">{key+1}</span> : <></>}
            <div className="caption">
              <span className="name">{`${item.player_information["First Name"]} ${item.player_information["Last Name"]} #${item.player_information["no_id"]}`}</span>
              <span className="score">{`Score: ${set_score(item)}`}</span>
              {station && <ProgressBarLine percentage={setNumProgress(item[station], station)} />}
            </div>
          </div>
          <div >
          <RightArrowSVG className="right_arrow" />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};
