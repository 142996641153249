import { React, useEffect, useState } from "react";
import { ReactComponent as EventSVG } from "../assets/icon_event.svg";
import { ReactComponent as LocalSVG } from "../assets/icon_local.svg";
import { ReactComponent as NationalSVG } from "../assets/icon_national.svg";
import { ReactComponent as DividerSVG } from "../assets/icon_divider.svg";
import "./style.css";

import { useAuth } from "../utils/authenticate";
import { useBaseURL } from "../utils/getBaseURL"; 

export const SeasonComponent = ({ extra_style }) => {
  const [numberEvents, setEvents] = useState("-");
  const [localEvents, setLocals] = useState("-");
  const [nationalEvents, setNationals] = useState("-");

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const getEventInfo = async (data) => {
    fetch(SERVER_URL + "/getEventNumbers", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setEvents(data.events);
          setLocals(data.local);
          setNationals(data.national);
        }
      });
  };

  useEffect(() => {
  
    // call the function
    getEventInfo()
      // make sure to catch any error
      .catch(console.error);
  }, [])
  

  return (
    <div className="season_item" style={extra_style}>
      <h1 className="season_title">THIS SEASON</h1>
      <div className="season_box w-full flex flex-row justify-evenly">
        <div className="data_item   flex flex-col">
          <div className="number_icon" >
            <span className="number mr-2">{numberEvents}</span>
            <EventSVG />
          </div>
          <div className=" ">
            <span className="season_description">Events</span>
          </div>
        </div>
        <DividerSVG />
        <div className="data_item   flex flex-col">
          <div className="number_icon">
            <span className=" number mr-2">{localEvents}</span>
            <LocalSVG />
          </div>
          <div className=" ">
            <span className="season_description">Locals</span>
          </div>
        </div>
        <DividerSVG />
        <div className="data_item   flex flex-col">
          <div className="number_icon">
            <span className="number mr-2">{nationalEvents}</span>
            <NationalSVG />
          </div>
          <div className=" ">
            <span className="season_description">Nationals</span>
          </div>
        </div>
      </div>
    </div>
  );
};
