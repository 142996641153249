import React from "react";
import { ReactComponent as TimeSVG } from "../../assets/icon_time.svg";
import { ReactComponent as PlayerSVG } from "../../assets/icon_player.svg";
import EventLogoSVG from "../../assets/icon_event_logo.svg";
import { ReactComponent as WhiteArrowSVG } from "../../assets/vector_white_arrow.svg";
import CardBackgroundSVG from "../../assets/card_background.svg";

import "./style.css";

const new_style = {
  backgroundImage: `url(${CardBackgroundSVG})`,
};

export const LargeCard = ({
  time_remaining,
  title,
  no_players,
  navigation_option,
  extra_style,
}) => {
  return (
    <div className="main_card" style={(extra_style, new_style)}>
      <div className="time_label">
        <TimeSVG /> Starts in {time_remaining} hrs
      </div>
      <div className="description">
        <div
          className="card_logo"
          style={{ backgroundImage: `url(${EventLogoSVG})`, backgroundSize: 'contain', 
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat', }}
        ></div>
        <div className="card_text">{title}</div>
      </div>
      <div className="card_info">
        <div className="player_info">
          <PlayerSVG />
          <span className="player_info_text">{no_players} players</span>
        </div>
        <div className="card_button" onClick={() => navigation_option()}>
          <div className="card_frame">
            Score a section
            <div className="card_main_button">
              <WhiteArrowSVG />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
