import { React } from "react";
import { ReactComponent as SearchSVG } from "../assets/icon_search.svg";

import { useNavigate } from "react-router-dom";
import "./style.css";

export const SearchBar = ({ caption, extra_style, go_to, inputText, setInputText }) => {
  const inputHandler = (e) => {
    setInputText(e.target.value);
  };

  const navigate = useNavigate();

  return (
    <div
      className="search_item"
      style={extra_style}
      onClick={() => {
        if (go_to) navigate(go_to);
      }}
    >
      <div className="search_box w-full flex flex-row items-center">
        <SearchSVG />
        <input
          type="text"
          onChange={(e) => {
            inputHandler(e);
          }}
          placeholder={caption}
          className="search_text"
          value={inputText}
        />
      </div>
    </div>
  );
};
