import React, { useState, useEffect } from "react";

import "./style.css";

export const QuickFilters = ({
  items,
  extra_style,
  initial_value,
  changePill,
  extra_funcs,
  resetPlayer,
  resetLeader
}) => {
  const [activePill, setActivePill] = useState(initial_value);

  const resetValues = () => {
    if (extra_funcs){
      resetPlayer("All");
      resetLeader("All");
    }
  }

  return items && (
    <div className="quick_section" style={extra_style}>
        {items.map((text, key) => (
            text.includes(activePill) ? <div key={key} className="pill_active">{text}</div> : <div key={key} index={key} className="pill" onClick={()=> {setActivePill(text); changePill(text); resetValues()}}>
              <>{text}</>
            </div>
          ))}
    </div>
  );
};