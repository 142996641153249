import React from "react";
import { BackLink } from "../BackLink/BackLink";
import { ReactComponent as MoreSVG } from "../../assets/icon_more_opt.svg";

import "./style.css";

export const BackHeader = ({ title, back_path, extra_style, main_color }) => {
  return (
    <div className="top_nav" style={extra_style}>
      <BackLink caption={"Back"} back_option={back_path} />
      <h1 className="top_tile" style={{color: main_color}}>{title}</h1>
      <MoreSVG />
    </div>
  );
};
