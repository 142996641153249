import React from "react";

import { BackHeader } from "../BackHeader/BackHeader";

import { ReactComponent as PlayerSVG } from "../../assets/icon_player_card.svg";
import { ReactComponent as ScoredSVG } from "../../assets/icon_scored_card.svg";
import { ReactComponent as CompletedSVG } from "../../assets/icon_completed_card.svg";
import { ReactComponent as DividerSVG } from "../../assets/icon_divider.svg";
import CardBackgroundSVG from "../../assets/eventBack.png";

import { useNavigate } from "react-router-dom";

import "./style.css";

const new_style = {
  backgroundImage: `url(${CardBackgroundSVG})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

export const LargeDetailCard = ({
  title,
  no_players,
  no_scored,
  no_done,
  button_text,
  back_path,
  eventID,
  main_color
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center w-full div_w_back" style={new_style}>
      <div className="d_main_card">
        <BackHeader
          title={title}
          back_path={back_path}
          extra_style={{ paddingTop: "35px" }}
          main_color={main_color}
        />
        <div className="description">
          <div className="season_box w-full flex flex-row justify-evenly">
            <div className="data_item flex flex-col">
              <div className="number_icon">
                <span className="number mr-2" style={{color: main_color}}>{no_players}</span>
                <PlayerSVG />
              </div>
              <div className=" ">
                <span className="season_description" style={{color: main_color}}>Players</span>
              </div>
            </div>
            <DividerSVG />
            <div className="data_item   flex flex-col">
              <div className="number_icon">
                <span className=" number mr-2" style={{color: main_color}}>{no_scored}</span>
                <ScoredSVG />
              </div>
              <div className=" ">
                <span className="season_description" style={{color: main_color}}>Scored</span>
              </div>
            </div>
            <DividerSVG />
            <div className="data_item   flex flex-col">
              <div className="number_icon">
                <span className="number mr-2" style={{color: main_color}}>{no_done}</span>
                <CompletedSVG />
              </div>
              <div className=" ">
                <span className="season_description" style={{color: main_color}}>Done</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="card_info"
          onClick={() =>
            navigate("/app/addPlayer", { state: { eventId: eventID } })
          }
        >
          <div className="add_player_btn">{button_text}</div>
        </div>
      </div>
    </div>
  );
};
