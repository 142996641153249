import { React, useEffect, useState } from "react";
import { BackHeader } from "../../components/BackHeader/BackHeader";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const AddPlayerPage = () => {
  const sports = ["Baseball", "Softball"];
  const divisions = ["7-8 years", "9-10 years", "11-12 years", "13-14 years"];
  const [events, setEvents] = useState([]);
  const [eventID, setEventID] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const SERVER_URL = useBaseURL();

  const { user, addUser } = useAuth();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const date_as_string = data.get("dob").split("-");
    // The server requires MM/DD/YY date
    const new_date = `${date_as_string[1]}/${date_as_string[2]}/${date_as_string[0].substring(2)}`
    console.log(`New date: ${new_date}`);
    addUser({
      event_id: data.get("events"), 
      firstName: data.get("fname"), 
      lastName: data.get("lname"),
      dob: new_date, 
      sport: data.get("sports")
    });
  };

  const getEvents = async () => {
    fetch(SERVER_URL + "/getEvents", {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setEvents(data.data);
        }
      });
  };

  useEffect(() => {
    // call the function
    setEventID(location.state?.eventId);
    getEvents()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  return (
    <div
      className="h-screen"
      style={{ paddingLeft: "16px", paddingRight: "16px" }}
    >
      <BackHeader
        title={"Add Player"}
        back_path="/app/home"
        extra_style={{
          paddingTop: "24px",
        }}
      />
      <div className="flex flex-col w-full justify-start text-left">
        <form onSubmit={(e) => {handleSubmit(e)}}>
          <h1 className="player_form_title">Please complete player's data</h1>
          <div className="player_form_lt">
            <label className="player_form_lt_header">First name</label>
            <input
              className="player_form_lt_input"
              type="text"
              id="fname"
              name="fname"
              placeholder="John"
              required
            />
          </div>
          <div className="player_form_lt">
            <label className="player_form_lt_header">Last name</label>
            <input
              className="player_form_lt_input"
              type="text"
              id="lname"
              name="lname"
              placeholder="Mora"
              required
            />
          </div>
          <div className="player_form_lt">
            <span className="player_form_lt_header">Date of birth</span>
            <input
              className="player_form_lt_input"
              type="date"
              id="dob"
              name="dob"
              required
            />
          </div>
          <div className="player_form_lt">
            <span className="player_form_lt_header">Sport</span>
            <div className="selectWrapper">
              <select className="selectBox" name="sports" id="sports">
                {sports.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="player_form_lt">
            <span className="player_form_lt_header">Event</span>
            <div className="selectWrapper">
              <select className="selectBox" name="events" id="events">
                {events.map((item, key) =>
                  item._id === eventID ? (
                    <option key={key} value={item._id} selected="selected">
                      {item.name}
                    </option>
                  ) : (
                    <option key={key} value={item._id}>
                      {item.name}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="action_buttons">
            <input type="submit" value="Add player" className="addBtn" />
            <div className="cancelBtn" onClick={() => navigate("/app/home")}>
              Cancel
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
