import { React, useEffect, useState } from "react";

import { ReactComponent as CheckSVG } from "../../assets/check_confirm.svg";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useNavigate, useParams } from "react-router-dom";
import { getPtsRunning } from "../../utils/scoring";

export const FinalScorePage = () => {
  const { eventID, title, section, idPlayer } = useParams();
  const [finalScore, setFinalScore] = useState();
  const [finalLabel, setFinalLabel] = useState();

  const navigate = useNavigate();

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const getEventData = async (eventId) => {
    fetch(SERVER_URL + "/getPlayer", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
      body: JSON.stringify({ player_id: idPlayer }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
            setFinalScore(section === "running" ? getPtsRunning(Math.min(...data.data[section])) : data.data[section].reduce((a, b) => a + b, 0));
            setFinalLabel(`${data.data.player_information["First Name"]} ${data.data.player_information["Last Name"]} #${data.data.player_information["no_id"]}`);
        }
      });
  };

  useEffect(() => {
    // call the function
    getEventData(eventID)
      // make sure to catch any error
      .catch(console.error);
  }, [eventID]);

  const keyword =
    section === "pitching" ? "Pitch" : section === "hitting" ? "Hit" : "Run";

  const page_title = keyword + " station";

  return (
    <div
      className="w-full desktop:w-[700px] h-screen m-auto"
      style={{ background: "#0041EB" }}
    >
      <div className="flex flex-col justify-center items-center">
        <span className="final_title">{page_title}</span>
        <div className="final_feedback">
          <CheckSVG />
          <div className="final_desc">
            <span className="final_score">{`${keyword} score: ${finalScore} pts`}</span>
            <span className="final_player">{`${finalLabel}`}</span>
          </div>
        </div>
        <div className="final_buttons">
          <div className="go_to_other" onClick={() => navigate(`/app/searchPlayer/${eventID}/${title}/${section}`)}>
            <span>Score another player</span>
          </div>
          <div className="go_to_station" onClick={() => navigate(`/app/eventDetail/${eventID}/${title}`)}>
            <span>Back to stations</span>
          </div>
        </div>
      </div>
    </div>
  );
};
