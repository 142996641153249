// Expected time as number. It could have 1 or more decimals. 
// We just tuncate the main part of the calculation.
// E.g. For 6.39, 6.4
export const getPtsRunning = (time) => {
  const rounded_time = Math.floor(time * 10) / 10;
  return time === 0
    ? 0
    : time < 4.0
    ? Math.round(600 + ((4.0 - rounded_time) * 50))
    : time >= 9.0
    ? 100
    : Math.round(600 - ((rounded_time - 4.0) * 100));
};
