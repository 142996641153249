/**
 * Return an array of objects that maps the teams. For now, we store them in alphabetical order (Except All team)
 * Each object determines shortname (name to display in navbar and for filtering) image (image name) and special_key for filtering
 */
import allImg from "../assets/teams/all.png";
import padresImg from "../assets/teams/padres.png";
import redSoxImg from "../assets/teams/red_sox.png";
import nationalsImg from "../assets/teams/nationals.png";
import rockiesImg from "../assets/teams/rockies.png";
import raysImg from "../assets/teams/rays.png";
import asImg from "../assets/teams/as.png";
import twinsImg from "../assets/teams/twins.png";
import cardinalsImg from "../assets/teams/cardinals.png";
import whiteSoxImg from "../assets/teams/white_sox.png";
import marlinsImg from "../assets/teams/marlins.png";
import rangersImg from "../assets/teams/rangers.png";
import marinersImg from "../assets/teams/mariners.png";
import nyMetsImg from "../assets/teams/ny_mets.png";
import brewersImg from "../assets/teams/brewers.png";
import redsImg from "../assets/teams/reds.png";
import royalsImg from "../assets/teams/royals.png";

export const teamsMap = [
  { shortname: "All", image: allImg, special_key: "All" },
  { shortname: "A's", image: asImg, special_key: "Oakland" },
  { shortname: "Brewers", image: brewersImg, special_key: "Brewers" },
  { shortname: "Cardinals", image: cardinalsImg, special_key: "Cardinals" },
  { shortname: "Mariners", image: marinersImg, special_key: "Mariners" },
  { shortname: "Marlins", image: marlinsImg, special_key: "Marlins" },
  { shortname: "Nationals", image: nationalsImg, special_key: "Nationals" },
  { shortname: "NY Mets", image: nyMetsImg, special_key: "Mets" },
  { shortname: "Padres", image: padresImg, special_key: "Padres" },
  { shortname: "Rangers", image: rangersImg, special_key: "Rangers" },
  { shortname: "Rays", image: raysImg, special_key: "Rays" },
  { shortname: "Red Sox", image: redSoxImg, special_key: "Red Sox" },
  { shortname: "Reds", image: redsImg, special_key: "Reds" },
  { shortname: "Rockies", image: rockiesImg, special_key: "Rockies" },
  { shortname: "Royals", image: royalsImg, special_key: "Royals" },
  { shortname: "Twins", image: twinsImg, special_key: "Twins" },
  { shortname: "White Sox", image: whiteSoxImg, special_key: "White Sox" },
];
