import { React, useEffect, useState } from "react";
import { BackHeader } from "../../components/BackHeader/BackHeader";
import { QuickFilters } from "../../components/QuickFilters/QuickFilters";
import { SearchBarActive } from "../../components/SearchBarActive/SearchBarActive";
import { EventListPlayer } from "../../components/EventListPlayer/EventListPlayer";

import "./style.css";

import { useAuth } from "../../utils/authenticate";
import { useBaseURL } from "../../utils/getBaseURL";
import { useParams } from "react-router-dom";

export const SearchPlayerPage = ({}) => {
  const { eventID, title, section } = useParams();
  const [rosterData, setRoster] = useState([]);
  const [selectedPillSection, setPillSection] = useState("All");

  const SERVER_URL = useBaseURL();

  const { user } = useAuth();

  const filterByCategory = (item) => {
    const divs = selectedPillSection.split(" "); // Age, Sport
    const ages = divs[0].split("-");
    return selectedPillSection === "All" ? true : item.player_information.Sport.startsWith(divs[1]) && (item.player_information.age === parseInt(ages[0]) ||  item.player_information.age === parseInt(ages[1]))
  }

  const getEventData = async (eventId) => {
    fetch(SERVER_URL + "/getRoster", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token,
      },
      body: JSON.stringify({ event_id: eventId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setRoster(data.data);
        }
      });
  };

  useEffect(() => {
    // call the function
    getEventData(eventID)
      // make sure to catch any error
      .catch(console.error);
  }, [eventID]);

  const page_title =
    (section === "pitching" ? "Pitch" : section === "hitting" ? "Hit" : "Run") +
    " station";
  const [filterText, setFilterText] = useState("");

  return (
    <div className="w-full desktop:w-[700px] h-full m-auto " style={{paddingLeft: "16px", paddingRight: "16px"}}>
      <BackHeader
        title={page_title}
        back_path={`/app/eventDetail/${eventID}/${title}`}
        extra_style={{ paddingTop: "35px" }}
      />
      <QuickFilters
        items={["All", "7-8 B", "9-10 B", "11-12 B", "13-14 B", "7-8 S", "9-10 S", "11-12 S", "13-14 S"]}
        extra_style={{ marginTop: "24px" }}
        initial_value={"All"}
        changePill={setPillSection}
      />
      <SearchBarActive
        changeText={setFilterText}
        extra_style={{
          marginTop: "24px",
        }}
      />
      <EventListPlayer
        items={rosterData.filter(
          (item) =>
            item.player_information["First Name"]
              .toLowerCase()
              .startsWith(filterText) ||
            item.player_information["Last Name"]
              .toLowerCase()
              .startsWith(filterText) ||
            item.player_information["no_id"].toString().startsWith(filterText)
        ).filter((item) => filterByCategory(item)).sort((a, b) => a.player_information["Last Name"].localeCompare(b.player_information["Last Name"]))}
        station={section}
        extra_style={{ marginTop: "24px", marginBottom: "24px" }}
        with_leaderboard={false}
        with_scoring={true}
        extra_data={{title: title, station:section}}
      />
    </div>
  );
};
