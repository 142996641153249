/**
 * Module to define the base URL path to query data
 * Since we have local and cloud servers, we define the following cases
 * For local server, set option 0
 * For Heroku server, set option 1
 * For AWS server, set option 2
 * @returns 
 */
export const useBaseURL = () => {
    const serverDev = 2;

    return serverDev === 0 ? "http://localhost:4000" : serverDev === 1 ? "https://rcx-server.herokuapp.com" : "https://rcxserver.rcxmatrix.com";
}