import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import "./style.css";
import { useNavigate } from "react-router-dom";

export const CarouselSection = ({
  main_tile,
  showSeeAll,
  seeAllPath,
  items,
  extra_style,
  naturalSlideHeight = 200,
  naturalSlideWidth = 10,
  deleteOne,
}) => {
  const navigate = useNavigate();

  return (
    <div className="car_section" style={extra_style}>
      <div className="header_div">
        <div className="left_section">
          <h1 className="main_title">{main_tile}</h1>
          <div className="numberResult">
            <span className="textResult">
              {deleteOne ? items.length - 1 : items.length}
            </span>
          </div>
        </div>
        {showSeeAll && (
          <div className="button_seeAll" onClick={() => navigate(seeAllPath)}>
            <span className="text_seeAll">See all</span>
          </div>
        )}
      </div>

      <CarouselProvider
        className="mt-[25px]"
        orientation={"horizontal"}
        dragEnabled={true}
        totalSlides={items.length}
        visibleSlides={1}
        isPlaying={true}
        interval={5000}
        naturalSlideHeight={naturalSlideHeight}
        naturalSlideWidth={naturalSlideWidth}
        infinite={true}
      >
        <Slider className="carousel__slider">
          {items.map((post, key) => {
            return (
              <Slide index={key}>
                <>{post}</>
              </Slide>
            );
          })}
        </Slider>
        <div className="flex flex-row justify-around mt-[-10px]">
        <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext>
        </div>
      </CarouselProvider>
    </div>
  );
};
