import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as HomeSVG } from "../../assets/icon_home_footer.svg";
import { ReactComponent as AddPlayerSVG } from "../../assets/button_add_player.svg";
import { ReactComponent as EventSVG } from "../../assets/icon_event_footer.svg";

import "./style.css";

export const Footer = ({ extra_style }) => {
  const navigate = useNavigate();
  const [activeTabs, setActiveTabs] = useState("");
  useEffect(() => {
    switch (activeTabs) {
      case "home":
        navigate("/app/home");
        break;
      case "addPlayer":
        navigate("/app/addPlayer");
        break;
      case "events":
        navigate("/app/events/All");
        break;
      default:
        break;
    }
  }, [activeTabs, navigate]);

  return (
      <div className="bottom_navbar" style={extra_style}>
        <div className="bn-tab">
          <HomeSVG
            onClick={() => navigate("/app/home")}
          />
        </div>
        <div className="bn-tab">
          <AddPlayerSVG
            onClick={() => setActiveTabs("addPlayer")}
          />
        </div>
        <div className="bn-tab">
          <EventSVG
            onClick={() => navigate("/app/events/All")}
          />
        </div>
      </div>
  );
};
