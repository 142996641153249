import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BackSVG } from "../../assets/icon_back.svg";

import "./style.css";

export const BackLink = ({ caption, back_option, extra_style }) => {
  const navigate = useNavigate();
  return (
    <div
      className="cta"
      style={extra_style}
      onClick={() => navigate(back_option)}
    >
      <BackSVG />
      <span className="cta_text">{caption}</span>
    </div>
  );
};
