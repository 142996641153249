import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/login/Login";
import { HomePage } from "./pages/home/Home";
import { AddPlayerPage } from "./pages/addplayer/AddPlayer";
import { AdminHomePage } from "./pages/adminhomepage/AdminHomepage";
import { EventHomePage } from "./pages/eventhomepage/EventHomePage";
import { EventDetailPage } from "./pages/eventdetailpage/EventDetailPage";
import { SearchPlayerPage } from "./pages/searchplayer/SearchPlayerPage";
import { PitchPage } from "./pages/pitchpage/PitchPage";
import { HitPage } from "./pages/hitpage/HitPage";
import { RunPage } from "./pages/runpage/RunPage";
import { FinalScorePage } from "./pages/finalscore/FinalScorePage";

//import { HomeLayout } from "./components/HomeLayout";
import "./App.css";

import { ProtectedLayout } from "./components/ProtectedLayout";

// <Route path="/app" element={<ProtectedLayout />}>

function App() {
  return (
    <div className="app">
      <Routes>
        <Route>
          <Route path="/" element={<LoginPage />} />
        </Route>

        <Route path="/app" element={<ProtectedLayout />}>
          <Route path="home" element={<AdminHomePage />} />
          <Route path="events/:section" element={<EventHomePage />} />
          <Route path="addPlayer" element={<AddPlayerPage />} />
          <Route path="eventDetail/:eventId/:title" element={<EventDetailPage />} />
          <Route path="searchPlayer/:eventID/:title/:section" element={<SearchPlayerPage />} />
          <Route path="pitching/:eventID/:title/:section/:idPlayer" element={<PitchPage />} />
          <Route path="hitting/:eventID/:title/:section/:idPlayer" element={<HitPage />} />
          <Route path="running/:eventID/:title/:section/:idPlayer" element={<RunPage />} />
          <Route path="finalScore/:eventID/:title/:section/:idPlayer" element={<FinalScorePage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
