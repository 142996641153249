import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

import { useBaseURL } from "./getBaseURL";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const SERVER_URL = useBaseURL();

  const login = async (data) => {
    
    fetch(SERVER_URL + "/api/login", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: data.email, password: data.password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          setUser(data);
          navigate("/app/home", { replace: true });
        }
      });
  };

  const addUser = async (data) => {
    
    fetch(SERVER_URL + "/createPlayer", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "auth-token": user.data?.token
      },
      body: JSON.stringify({ event_id: data.event_id, firstName: data.firstName, lastName: data.lastName, dob: data.dob, sport: data.sport }),
    })
      .then((response) => response.json())
      .then((data) => {
        if ("error" in data) {
          alert(data.error);
        } else {
          alert("New player added!");
        }
      });
  };

  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      addUser
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
