import * as React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useAuth } from "../../utils/authenticate";

import "./style.css";
import { ReactComponent as LogoSVG } from "../../assets/logo_login.svg";

export const LoginPage = () => {
  const { login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <div className="form_login">
      <Box
        sx={{
          paddingTop: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoSVG />
        <Box className="box_login box_login_t">
          <form onSubmit={e => {handleSubmit(e)}}>
            <h1 className="title">Game Day</h1>
            <h3 className="subtitle">EVENT MANAGER</h3>
            <div className="mx-3">
              <div className="login_section mt-[35px]">
                <label className="text_input">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="box_input"
                />
              </div>
              <div className="login_section mt-[24px]">
                <label className="text_input">
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="box_input"
                />
              </div>
              <Grid container>
                <Grid item>
                    <Link href="#" variant="body2" className="text-right">
                      {"Forgot your password?"}
                    </Link>
                </Grid>
              </Grid>
              <input
                type="submit"
                value="Let’s go!"
                className="button_submit"
              />
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
};
